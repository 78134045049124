.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}

.split-screen {
  display: flex;
  flex-direction: column;
  /* Vertical split */
  width: 100vw;
  /* Full viewport width */
  height: 100vh;
  /* Full viewport height */
  overflow: hidden;
  /* Prevents scrolling */
}

.left-side,
.right-side {
  flex: 1 !important;
  /* Each side takes up half the space */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.left-side {
  background-color: black;
  color: white;
}

.left-side .right-side p {
  margin: 0.5px;
}

.right-side {
  background-color: white;
  color: black;
}

/* Responsive Design */
@media (max-width: 768px) {
  .split-screen {
    flex-direction: row;
    /* Horizontal split on smaller screens */
  }
}

.left-side b {
  color: red;
}